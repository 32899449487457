<template>
  <AppRow only-owner>
    <template v-slot>
      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-select
          v-model="purseType"
          :items="selectPurseType"
          background-color="#464646"
          class="rounded-lg"
          flat
          hide-details="auto"
          item-text="text"
          item-value="key"
          label="Игровая валюта"
          solo
          @change="refresh"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <DatePicker
          label="Диапазон дат"
          @save="saveDates"
        />
      </v-col>

      <v-col cols="12">
        <ApolloQuery
          :query="require('../graphql/queries/getDashboardInfo.gql')"
          :variables="{
            dateFrom,
            dateTo,
            purseType: initPurseType()
          }"
        >
          <template v-slot="{ result: { error: dashboardError, data: dashboardData }, isLoading: dashboardIsLoading }">
            <AppNotification
              v-if="dashboardError"
              :message="dashboardError.message"
            />
            <v-row v-else-if="dashboardData || dashboardIsLoading === 1">
              <v-col
                v-for="item of cards"
                :key="item.value"
                :md="item.value === 'conversionFromRegistrationToDeposit' || item.value === 'conversionToRepeatedDeposit' ? '6' : '4'"
                cols="12"
                sm="6"
              >
                <InfoCard
                  :data="dashboardData"
                  :icon="!item.isCurr ? '' : initPurseType()"
                  :item="item"
                  :loading="dashboardIsLoading"
                  property="getDashboardInfo"
                />
              </v-col>
            </v-row>
          </template>
        </ApolloQuery>
      </v-col>

      <v-col
        class="text-center text-h5"
        cols="12"
      >
        Когортный анализ
      </v-col>

      <v-col cols="12">
        <v-card
          class="px-4 pb-3"
          flat
        >
          <v-data-table
            :headers="headers"
            :items="[]"
            :items-per-page="5"
          />
        </v-card>
      </v-col>

      <v-col
        class="text-center text-h5"
        cols="12"
      >
        Режимы
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model.number="betsLoading"
          :append-icon="betsLoading !== bets ? 'mdi-check' : ''"
          background-color="#464646"
          class="rounded-lg"
          flat
          hide-details="auto"
          label="Кол-во ставок"
          solo
          @click:append="bets = betsLoading"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model.number="amountLoading"
          :append-icon="amountLoading !== amount ? 'mdi-check' : ''"
          background-color="#464646"
          class="rounded-lg"
          flat
          hide-details="auto"
          label="Сумма ставок"
          solo
          @click:append="amount = amountLoading"
        />
      </v-col>

      <v-col>
        <v-card
          class="px-4 pb-3"
          flat
        >
          <v-data-table
            :headers="headersGameMode"
            :items="[]"
            :items-per-page="5"
          />
        </v-card>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import DatePicker from '@/components/DatePicker.vue';
import InfoCard from '@/components/InfoCard.vue';

export default {
  components: {
    AppRow,
    DatePicker,
    InfoCard,
  },
  data() {
    return {
      purseType: 0,
      selectPurseType: [
        { text: 'INR', key: 0 },
        { text: 'BTC', key: 1 },
        { text: 'USD', key: 2 },
        { text: 'EUR', key: 3 },
        { text: 'ETC', key: 4 },
        { text: 'UAH', key: 5 },
        { text: 'RUB', key: 6 },
        { text: 'LTC', key: 7 },
        { text: 'DEMO', key: 8 },
        { text: 'ETH', key: 9 },
        { text: 'BRL', key: 10 },
        { text: 'BCH', key: 11 },
        { text: 'BNB', key: 12 },
        { text: 'DASH', key: 13 },
        { text: 'DOGE', key: 14 },
        { text: 'TRX', key: 15 },
        { text: 'USDT', key: 16 },
        { text: 'XMR', key: 17 },
        { text: 'ZEC', key: 18 },
        { text: 'XRP', key: 19 },
        { text: 'KZT', key: 20 },
        { text: 'VND', key: 21 },
        { text: 'UZS', key: 22 },
        { text: 'IDR', key: 23 },
        { text: 'AZN', key: 24 },
        { text: 'KGS', key: 25 },
        { text: 'PKR', key: 26 },
        { text: 'BDT', key: 27 },
        { text: 'CLP', key: 28 },
        { text: 'PEN', key: 29 },
        { text: 'COP', key: 30 },
        { text: 'MXN', key: 31 },
        { text: 'PYG', key: 32 },
        { text: 'UYU', key: 33 },
        { text: 'BOB', key: 34 },
        { text: 'CRC', key: 35 },
        { text: 'GTQ', key: 36 },
        { text: 'PAB', key: 37 },
        { text: 'NIO', key: 38 },
        { text: 'ARS', key: 39 },
        { text: 'SVC', key: 40 },
      ],
      dateFrom: '',
      dateTo: '',
      cards: [
        {
          text: 'GGR',
          value: 'ggr',
          isCurr: true,
        },
        {
          text: 'NGR',
          value: 'ngr',
          isCurr: true,
        },
        {
          text: 'ARPU',
          value: 'arpu',
          isCurr: true,
        },
        {
          text: 'ARPPU',
          value: 'arppu',
          isCurr: true,
        },
        {
          text: 'LTV',
          value: 'ltv',
          isCurr: true,
        },
        {
          text: 'Lifetime',
          value: 'lifetime',
          isCurr: false,
        },
      ],
      dashboardData: {
        getDashboardInfo: {
          ggr: 0,
          arpu: 0,
          arppu: 0,
          ngr: 0,
          dau: 0,
          ltv: 0,
          lifetime: 0,
        },
      },
      headers: [
        { text: 'Дата:', value: 'date', class: 'white--text text-subtitle-2' },
        { text: 'Регистраций:', value: 'registrations', class: 'white--text text-subtitle-2' },
        { text: 'Депозитов:', value: 'deposits', class: 'white--text text-subtitle-2' },
        { text: 'Выводов:', value: 'withdraws', class: 'white--text text-subtitle-2' },
        { text: 'Доход:', value: 'revenue', class: 'white--text text-subtitle-2' },
      ],
      betsLoading: '',
      bets: '',
      amountLoading: '',
      amount: '',
      headersGameMode: [
        { text: 'Режим:', value: 'gameMode', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во ставок:', value: 'betsQty', class: 'white--text text-subtitle-2' },
        { text: 'Сумма ставок:', value: 'betsAmount', class: 'white--text text-subtitle-2' },
        { text: 'Выиграно:', value: 'winsAmount', class: 'white--text text-subtitle-2' },
        { text: 'Проиграно:', value: 'looseAmount', class: 'white--text text-subtitle-2' },
        { text: 'GGR:', value: 'ggr', class: 'white--text text-subtitle-2' },
      ],
    };
  },
  methods: {
    initPurseType() {
      const items = this.selectPurseType.filter(p => p.key === this.purseType);
      return items.length ? items[0].text : '';
    },
    refresh(value) {
      this.purseType = value;
    },
    saveDates(dates) {
      [this.dateFrom, this.dateTo] = dates;
    },
    getIcon(value) {
      if (value === 'arpu' || value === 'replenishment') {
        return this.initPurseType();
      }

      if (value === 'conversionFromRegistrationToDeposit' || value === 'conversionToRepeatedDeposit') {
        return '%';
      }

      return '';
    },
  },
};
</script>
